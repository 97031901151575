@import 'src/styles/vars';

.TargetRequestModal__body {
  margin-bottom: 50px;
}

.TargetRequestModal__description {
  font-weight: 500;
}

.TargetRequestModalForceToggle__checkbox {
  top: 30%;
}

.TargetRequestModalForceToggle__label {
  color: $brand-danger;
}

@import 'src/styles/vars';

.MaturityPanel__tabs {
  display: flex;
  background-color: $gray-really-light;
  border-radius: 6px;
  border: 1px solid $gray-lighter;
  padding: 5px;
}

.MaturityPanel__tab {
  padding: 5px;
  border-radius: 6px;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 0.8em;
  text-align: center;
  color: $gray-medium-dark;

  &:hover {
    border-color: $gray-lighter;
    cursor: pointer;
  }

  &__selected {
    border-color: $gray-lighter;
    background: white;
    color: black;

    &:hover {
      cursor: default;
    }
  }
}

.MaturityPanel__badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $brand-danger;
  border-radius: 50%;
  font-weight: 500;
  color: white;
  margin-left: 4px;
  width: 18px;
  height: 18px;
}

.MaturityPanel__tabsContents {
  overflow-y: auto;
}

@import 'src/styles/vars';

.PercentageCell {
  &.Maturity__not-requested {
    opacity: 0.4;
  }
}

.MaturityCell--clicked,
.MaturityCell--clickable:hover {
  text-decoration: underline;
  color: $gray-medium;
  cursor: pointer;
}

.MaturityCell__details {
  font-weight: 500;

  & > :first-child {
    flex: 1;
  }

  & > div {
    margin-right: 0.5rem;
  }

  &.MaturityCell__details--ok {
    color: $brand-success;
  }

  &.MaturityCell__details--ko {
    color: $brand-danger;
  }
}

.MaturityCell__rest {
  color: $gray-really-light;
  font-style: italic;
}

@import 'src/styles/vars';

.SeeEditAllAsset {
  display: flex;
  flex-direction: column;

  &__buttons {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  &__assets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 44px;
  }
}

@import 'src/styles/vars';

.MaturityDeadlineCell {
  .mdi {
    vertical-align: middle;
    font-size: 1.3rem;
    &.mdi-calendar-clock {
      color: $brand-orange;
    }
    &.mdi-calendar-alert {
      color: $brand-danger;
    }
  }

  &--not-released i {
    display: none;
  }
}

.MaturityDeadlineCell__details {
  font-weight: 500;

  & > :first-child {
    flex: 1;
  }

  & > div {
    margin-right: 0.5rem;
  }
  .MaturityDeadlineCell__details--ok {
    color: $brand-success;
  }

  .MaturityDeadlineCell__details--nok-future {
    color: $brand-orange;
  }

  .MaturityDeadlineCell__details--nok-past {
    color: $brand-danger;
  }
}

.MaturityDeadlineCell__information {
  font-style: italic;
  color: $gray-slightly-lighter;
}

@import 'src/styles/vars';

$date-height: 2rem;

.InputDate.InputField__input {
  position: relative;
}

.InputDate__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  border: 1px solid $input-border-color;
  background-color: $brand-white;

  &.InputDate__item--readOnly {
    background-color: $readonly-bg;
    border-color: $readonly-bg;
  }

  &.InputDate__item--small {
    width: 170px;
  }
}

.InputDate__input {
  border: none;
  height: $date-height;
  line-height: $date-height - 0.4;
  padding: 0.2rem 0.4rem;
  max-width: 175px;

  &.InputDate__input--small {
    max-width: 115px;
  }

  &[disabled] {
    background-color: transparent;
  }
}

.InputDate__deleteIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $date-height;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.InputDate__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  width: $date-height;
  height: $date-height;
  background: $brand-primary;
  border: 0;
  color: $brand-white;
  cursor: pointer;
  flex-shrink: 0;

  &.InputDate__icon--active:hover {
    background: $brand-primary-btn-hover;
  }

  &.InputDate__icon--readOnly {
    background-color: $readonly-bg-darkest;
    cursor: default;
  }
}

.InputDate__calendar {
  display: none;
  width: 315px;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 1;
  border: 1px solid $brand-primary;
  background: white;

  &.InputDate__calendar--opened {
    display: block;
  }
}

.DateRangePicker__WeekdayHeading {
  font-weight: 300;
  cursor: default;
}

.DateRangePicker__CalendarSelection {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

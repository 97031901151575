@use 'sass:math';

@import 'bootstrap_variables';

// Form variables
// -------------------------
$field-height-base: $input-height-base + math.div($grid-gutter-width, 2);
$field-padding-vertical: math.div(
  $grid-gutter-width,
  4
); // divided by 2 gives the padding of each column

$font-size-xlarge: 1.5rem;
$navbar-size: 40px;

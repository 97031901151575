@import 'src/styles/vars';

.Preview {
  background: $gray-lighter;
  padding: 10px;
}

.Preview__headline {
  margin-top: 5px;
  font-weight: 500;
  color: $brand-secondary;
}

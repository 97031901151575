@import 'src/styles/vars';

.Tipster {
  display: flex;
  border: 1px solid;
  border-radius: $border-radius-base;
  margin-bottom: $half-margin-base;
  padding: $half-margin-base $half-margin-base $half-margin-base 0;
  align-items: center;

  // /!\ needed for IE11 /!\
  width: 100%;

  &.Tipster--iconTop {
    align-items: flex-start;
  }

  &.Tipster--success {
    border-color: rgba($brand-success, 0.5);
    background: $brand-success-disabled;

    .Tipster__icon {
      color: $brand-success;
    }
  }

  &.Tipster--info {
    border-color: rgba($brand-secondary, 0.5);
    background: $brand-secondary-hover;

    .Tipster__icon {
      color: $brand-secondary;
    }
  }

  &.Tipster--warning {
    border-color: $brand-highlight-secondary;
    background: rgba($brand-highlight-secondary, 0.2);

    .Tipster__icon {
      color: $brand-highlight-secondary;
    }
  }

  &.Tipster--danger {
    border-color: $brand-danger;
    background: $brand-danger-hover;

    .Tipster__icon {
      color: $brand-danger;
    }
  }

  &.Tipster--compact {
    border: 0;
    background: inherit;
    margin: 0;
    padding: 0;

    .Tipster__icon {
      flex: 0 0 20px;
      width: 20px;
    }
  }
}

.Tipster__icon {
  flex: 0 0 40px;
  width: 40px;
  font-size: 1.5em;
  text-align: center;
  margin-right: $half-margin-base-horizontal;
}

.Tipster__text {
  text-align: left;
  flex: 1;
}

@import 'src/styles/vars';

.AllowanceLevel {
  display: flex;
  align-items: center;
  margin-top: $half-margin-base;
  + .AllowanceLevel {
    margin-top: $half-margin-base;
  }
}

.AllowanceLevel__metadata {
  flex-basis: 75%;
  display: flex;
  align-items: center;
  border: 1px solid $gray-lighter;
  border-radius: $border-radius-small;
  padding: $padding-xs-horizontal;
}

.AllowanceLevel__rankWrapper {
  display: flex;
  align-items: center;
}

.AllowanceLevel__rank {
  display: block;
  border-radius: 50%;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
}

.AllowanceLevel__items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: $padding-large-horizontal;
}

.AllowanceLevel__item {
  display: flex;
  align-items: center;
}

.AllowanceLevel__type {
  flex-basis: 30%;
  max-width: 30%;
}

.AllowanceLevel__unit {
  flex-basis: 20%;
  max-width: 20%;
  padding-left: $padding-xs-horizontal;
}

.AllowanceLevel__description {
  flex-grow: 1;
  max-width: 60%;
  padding-left: $padding-xs-horizontal;
}

.AllowanceLevel__values {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  padding: $padding-xs-horizontal;
}

.AllowanceLevel__itemValues {
  display: flex;
  align-items: center;
}

.AllowanceLevel__itemValue {
  flex-grow: 1;
  flex-basis: 0;
  + .AllowanceLevel__itemValue {
    margin-left: 5px;
  }
}

.AllowanceLevel__deleteIcon {
  position: absolute;
  right: 20px;
  font-size: large;
  color: $gray-light;
  &:hover {
    cursor: pointer;
    color: $gray-dark;
  }
}

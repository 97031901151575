@use 'sass:math';

@import 'src/styles/vars';

.Notification {
  z-index: 120;
  display: none;
  align-items: center;
  justify-content: space-between;
  background: $gray;
  color: #fff;
  overflow: hidden;

  $scale-timing: 400ms;
  $opacity-timing: math.div($scale-timing, 2);

  &.NotificationAnimation-enter {
    display: flex;
    transform: scaleY(0);
    .Notification__message,
    .Notification__discard {
      opacity: 0;
    }
  }
  &.NotificationAnimation-enter-active {
    display: flex;
    transition: transform $scale-timing ease;
    transform-origin: 50% 0;
    transform: scaleY(1);
    .Notification__message,
    .Notification__discard {
      transition: opacity $opacity-timing $opacity-timing ease;
      opacity: 1;
    }
  }
  &.NotificationAnimation-enter-done {
    display: flex;
  }
  &.NotificationAnimation-exit {
    display: flex;
    transform: scaleY(1);
    .Notification__message,
    .Notification__discard {
      opacity: 1;
    }
  }
  &.NotificationAnimation-exit-active {
    display: flex;
    transition: transform $scale-timing ease;
    transform-origin: 50% 0;
    transform: scaleY(0);
    .Notification__message,
    .Notification__discard {
      transition: opacity $opacity-timing ease;
      opacity: 0;
    }
  }
  &.NotificationAnimation-exit-done {
    display: none;
  }

  &.Notification--base {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
  }

  &.Notification--public {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &.Notification--modal {
    position: relative;

    &.Notification--absolute {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &.Notification--block {
    display: block;
    padding: 0.5rem 2rem;
  }

  &.Notification--success {
    background: $brand-success;
  }

  &.Notification--error {
    background: $brand-warning;
  }
}

.Notification__discard {
  margin-right: 1rem;
  font-size: x-large;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;

  &.Notification__discard--invisible {
    visibility: hidden;
  }
}

.Notification__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;

  .ExpandableWell__collapse,
  .ExpandableWell__expand {
    color: inherit;
  }

  div + div {
    padding-top: 0.25rem;
  }

  .btn-link {
    color: $brand-white;
    &:hover {
      color: $brand-white;
    }
  }
}

.Notification--modal {
  position: relative;
  margin-top: -$margin-base;
}

.Notification__stacktrace {
  white-space: pre-wrap;
  line-height: 1rem;
  max-height: 10rem;
  overflow: auto;
  color: $brand-white;
}

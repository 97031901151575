@import 'src/styles/vars';

.AssetV2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  width: 13rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &--error {
    border: 1px solid $brand-danger;
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    margin-bottom: 4px;
    border: 1px solid $gray-lighter;

    &:hover &__backdrop {
      display: flex;
    }

    &:hover &__button {
      visibility: visible;
    }

    &__placeholder {
      width: 125px;
      height: 96px;
      font-size: 4rem;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
    }

    &__image {
      height: 96px;
    }

    &__button {
      position: absolute;
      visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__row {
    margin-left: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  &__label {
    color: $brand-default;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__file-type__label {
    color: $brand-black;
  }

  &__chip {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 0.75rem;
    height: 1.1rem;
    padding: 0.1rem 0.5rem;
  }

  &__chip--archived {
    background-color: #b9b9b9;
    color: $brand-white;
  }
}

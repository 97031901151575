@import 'src/styles/vars';

.MaturitiesDeadlinesCell {
  .mdi {
    vertical-align: middle;
    font-size: 1.3rem;
    &.mdi-calendar-blank {
      color: $gray-slightly-lighter;
    }
    &.mdi-calendar-clock {
      color: $brand-orange;
    }
    &.mdi-calendar-alert {
      color: $brand-danger;
    }
    &.mdi-calendar-check {
      color: $brand-success;
    }
  }
}

.MaturitiesDeadlinesCell__information {
  font-style: italic;
  color: $gray-slightly-lighter;
}

.MaturitiesDeadlinesCell__others {
  color: $brand-primary;
  font-weight: 300;
  font-size: 0.8rem;
}

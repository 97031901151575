.EmailIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background: #166a83;
  line-height: 4rem;
  font-size: 32px;
  color: white;

  & > i.mdi {
    display: block;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

@import 'src/styles/vars';

.BigDottedButton {
  display: block;
  border: 1px dashed #3fa2b2;
  margin-top: $half-margin-base;
  color: #3fa2b2;
  font-size: 22px;
  padding: 10px;
  background: white;
  width: 100%;

  i {
    margin-left: 5px;
    margin-right: 10px;
  }

  &.BigDottedButton--disabled {
    color: $brand-default-disabled;
    border-color: $brand-default-disabled;
  }
}

.AddSharingUnitButton {
  margin: 0 20px;
  width: calc(100% - 40px);
}

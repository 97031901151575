@import 'src/styles/vars';

.OrganizationLegalIdentifierList__Detail {
  font-style: italic;
  color: $gray-medium;
}

.OrganizationLegalIdentifierList__label {
  margin-top: $padding-large-vertical;
  padding-right: 15px;
  padding-left: 15px;
}

.OrganizationLegalIdentifierList__action {
  margin-right: $padding-base-vertical;
}

.OrganizationLegalIdentifierList__addLegalIdentifier {
  margin-top: 2rem;
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;
  border: 1px dashed $brand-primary;
  color: $brand-primary;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  font-size: 1rem;
  justify-content: center;
}

.OrganizationLegalIdentifierList__delete {
  font-size: $font-size-h3;
  color: $gray-medium;
  padding-left: $padding-large-vertical;
  cursor: pointer;

  &:hover {
    color: $brand-secondary;
  }
}

.OrganizationLegalIdentifier__notification {
  ul {
    margin: 0;
  }
}

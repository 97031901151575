@use 'sass:math';

@import 'src/styles/vars';

.SharingUnit {
  padding: 20px;
}

.SharingUnit__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.SharingUnit__title_and_status {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 95%;
  > div {
    flex-shrink: 0;
  }
  > button {
    min-width: 0;
  }
}

.SharingUnit__title {
  margin-bottom: 0;
  margin-right: 10px;
  min-width: 0;
}

button .SharingUnit__title {
  text-decoration: underline;
}

.SharingUnit__delete {
  display: flex;
  color: $gray-light;
  background-color: $brand-white;
  border: 0;
  align-items: center;
  > i {
    font-size: 1.6rem;
  }
}

.SharingUnit__delete--force {
  color: $brand-danger;
}

.SharingUnit .helpTooltip {
  margin-left: math.div($margin-base-horizontal, 3);
}

.SharingUnit__SyncStatus,
.SharingUnit__Status {
  text-align: center;
  padding-right: $padding-xs-horizontal;

  .Draft,
  .Pending,
  .Accepted,
  .Warning,
  .Refused {
    border-radius: $border-radius-very-large;
    padding: $padding-xs-vertical $padding-small-horizontal;
    border-width: 1px;
    border-style: solid;
    display: inline;
  }

  .Draft {
    border-color: $brand-inprogress;
    color: $brand-inprogress;
  }

  .Pending {
    border-color: $brand-info;
    color: $brand-info;
  }

  .Accepted {
    border-color: $brand-success;
    color: $brand-success;
  }

  .Refused {
    border-color: $brand-warning;
    color: $brand-warning;
  }

  .Warning {
    border-color: $brand-highlight-secondary;
    color: $brand-highlight-secondary;
  }
}

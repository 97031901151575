@use 'sass:math';

@import 'src/styles/vars';

.ProductPreviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProductPreviews_content_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.ProductPreviews_content {
  display: flex;
  flex-flow: column;
  align-items: center;
  min-width: 0;
  flex-basis: 50%;
  padding: $padding-small-vertical $padding-small-horizontal;
  .ProductReference {
    margin: 0;
  }
}

.ProductPreviews__fakeLink {
  color: $brand-primary;
  text-decoration: underline;
  margin-top: math.div($half-margin-base, 2);
}

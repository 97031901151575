@import 'src/styles/vars';

.MaturityCompletion {
  margin-right: 3px;
}

.MaturityCompletion__maturityLabel {
  color: $brand-default;
  font-weight: 300;
}

.MaturityCompletion__lateLabel {
  color: $brand-danger;
  font-weight: 300;
  display: flex;
}

@import 'src/styles/vars';

$autocomplete-height: 30px;
$autocomplete-min-width: 200px;
$autocomplete-list-height: 150px;

.Autocomplete {
  display: block;
  width: 100%;
  min-width: $autocomplete-min-width;
}
.Autocomplete__input {
  position: relative;
  display: block;
}
.Autocomplete__inputSearch {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $gray-light;
  border-radius: 3px;
  padding: 8px 14px;
  margin: 0;
  box-shadow: none !important;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:disabled {
    background-color: $input-bg-disabled;
    font-style: italic;
  }
}
.Autocomplete__inputSearch--opened {
  border-radius: 3px 3px 0 0;
}
.Autocomplete__inputSearch--white {
  background-color: white;
}
.Autocomplete__searchIcon {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 20px;
  cursor: default;
  transform: translateY(-50%);

  &.disabled {
    color: $text-disabled-color;
  }
}
.Autocomplete__list {
  position: absolute;
  visibility: hidden;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  padding: 0;
  max-height: $autocomplete-list-height;
  overflow: auto;
  z-index: 1;
  li {
    cursor: pointer;
    padding: 8px 15px;
    list-style: none;
    &:hover,
    &.selected {
      background: $alk-background-hover;
    }
  }
}
.Autocomplete__list--opened {
  visibility: visible;
  border: 1px solid $gray-light;
  background: white;
}
.Autocomplete__selected {
  display: inline-flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 5px 0 0;
  background: $gray-lighter;
  border-radius: 3px;
}
.Autocomplete__labelSelected {
  max-width: 95%;
  padding: 3px 15px 0;
}
.Autocomplete__deleteSelected {
  display: inline-block;
  box-sizing: border-box;
  width: $autocomplete-height;
  min-width: $autocomplete-height;
  height: $autocomplete-height;
  background: $brand-primary;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  &:hover {
    background: $brand-primary-btn-hover;
  }
  &:disabled {
    display: none;
  }
}
